import React from 'react'
import Layout from '../../components/Layout'
import {Location} from '@reach/router'
import Content from '../../components/Content'
export default () => (  
    <Location>
        {
            props=>(
                <Layout location={props.location}>
                    <Content>
                    <h2>
                        Seamless Integration
                    </h2>
                    <p>
                        US Alarms connects all your the key devices, giving you smart, responsive automation and seamless control that you can’t get from stand-alone solutions. US Alarms’ intelligent services platform is the foundation for your connected property – giving you access to advanced control, better awareness, and reliable, state of the art technology.
                    </p>

                    <h2>
                        Adaptive Learning
                    </h2>
                    <p>
                        The power of a truly connected property is in the details. It’s more than having automated lights, or a thermostat that adjusts on schedule. US Alarms assembles the intelligence from all of your connected devices and puts them to work together, making your property an ecosystem that learns and adapts to meet your needs.
                    </p>

                    <h2>
                        Multi-sensor Learning
                    </h2>
                    <p>
                        Many solutions use a single data point, like motion, to try to understand your activity patterns. US Alarms uses the data across all of the connected points in your property — security sensors, system arming, door and window sensors, motion activity and more. With more accurate information, your system better understands your routines and can be optimized to meet your needs.
                    </p>

                    <h2>
                        Command Link
                    </h2>
                    <p>
                        US Alarms enables your devices to all talk to each other. Instead of sending single commands to your lights, thermostat and door locks, everything works together with customizable triggers. You can do less, but get an added level of security and convenience.
                    </p>

                   
                        <p><strong>Control</strong>
                        <br/>
                        
                        Personalization Engine<br/>
                        The flexible rules engine from Alarm.com lets you have precise control of your system so that you get exactly what you need. Have one setting for when the kids arrive home after school, and a different setting for weekends.</p>

                    <p><strong>Simplify</strong><br/>
                    Triggers<br/>
                    Simplify your daily routine by putting your property to work for you. Easy to use custom triggers let you consolidate multiple actions into one to control your lights, locks and thermostat automatically. For example, set a trigger to automatically arm your system and turn off the lights when you leave and lock the front door, so that you don’t have to remember to do it.</p>

                    <p><strong>Know</strong><br/>
                    Instant Alerts<br/>
                    Know the instant something happens at your property. Get real time text or image alerts when the door is unlocked, get a video alert if there is motion in the basement or get notified if someone goes into your home office when you’re away. Alerts are customizable and can be controlled by device, time of day, day of week and can be turned on and off anytime.</p>

                    <p><strong>Location-Based Automation</strong><br/>
                    Geo-Services<br/>
                    Have your property automatically adjust when it detects that you’ve left your geo-fence, or when you’re approaching it. Use Geo-Services to have video monitoring pause for privacy when you’re home, have your thermostat begin adjusting to the right temperature, or get a reminder if you leave without closing the garage.</p>

                    <h2>
                        Constant Evolution: Innovation + Flexibility
                    </h2>
                    <p>  It’s important to invest in something that will grow with you and keep up with market innovation. We partner with industry leaders to provide an array of devices to choose from, and we continually create new features to make your home better. What does that mean? You won’t get stuck waiting for that garage door control that can work with the thermostat you invested so much in last year.</p>

                    <h2>
                        Continuous Updates
                    </h2>
                    <p>
                        We routinely launch new functionality to enhance your system. Accessing the latest features is as easy as updating your app.
                    </p>

                    <h2>
                        Add Devices
                    </h2>
                    <p>
                        US Alarms connects devices through a sophisticated cloud service so that new ones can be added as they become available.
                    </p>
                    </Content>
                </Layout>
            )
        }
    </Location>
)